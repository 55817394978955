import React, {useEffect, useState} from 'react';
// @ts-ignore
import {
    FlexGrid,
    Header,
    HeaderName,
    Modal,
    TextInput,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarMenu,
    TableToolbarSearch,
    TableToolbarAction,
    Table,
    TableHead,
    TableRow,
    Tile,
    TableBody,
    TableCell,
    TableHeader,
    TableToolbarContent,
    Button,
    TableSelectRow,
    FileUploader,
    Loading,
    IconButton
} from '@carbon/react';
import { Reset } from '@carbon/icons-react';
import authenticationProvider from "../api/authenticationProvider";
import {API_URL} from "../constants";
import {Prediction} from "../api/classes/prediction";


function ViewPredictions(props: any) {
    const auth: authenticationProvider = props?.auth
    let [isLoading, setLoading] = useState(false);

    const getPredictions = async (): Promise<Prediction[]> => {
        setLoading(true);
        return await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/predictions`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then((data) => data.json())
            .then((response) => {
                console.log("updated predictions");
                setLoading(false);
                return response.body.predictions as Prediction[];
            });
    }
    let [predictions, setPredictions] = useState<Prediction[]>(null!);

    useEffect(() => {
        if (predictions) {
            return;
        }
        const load = async () => {
            await getPredictions().then((p) => setPredictions(p));
        }
        load()
    }, [predictions])

    return (
        <>
            <Loading active={isLoading} withOverlay />
            <IconButton
                style={{marginBottom: 32}}
                onClick={async () => {
                    await getPredictions()
                        .then((p) => setPredictions(p))
                }}
            >
                <Reset />
            </IconButton>
            {
                predictions ? (
                    <div>
                        {
                            predictions.map((p) => (
                                <Tile style={{marginBottom: 16}}>
                                    <div style={{marginBottom: 16, fontWeight: "bold"}}>
                                        {p.created_at}
                                    </div>
                                    <div style={{marginBottom: 16}}>
                                        <em>Customer: </em> { p.prompt }
                                    </div>
                                    <div style={{marginBottom: 8}}>
                                        <em>Assistant: </em>{ p.response }
                                    </div>
                                </Tile>
                            ))
                        }
                    </div>
                ) : (
                    <div></div>
                )
            }
        </>
    )
}

export default ViewPredictions;