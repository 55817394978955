import React, {useState} from 'react';
// @ts-ignore
import {
    FlexGrid,
    Header,
    HeaderName,
    Modal,
    TextInput,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarMenu,
    TextArea,
    TableToolbarSearch,
    TableToolbarAction,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableHeader,
    TableToolbarContent,
    Button,
    TableSelectRow,
    FileUploader,
    Loading
} from '@carbon/react';
import authenticationProvider from "../api/authenticationProvider";
import {API_URL} from "../constants";
import {create} from "domain";
import Mapping from "../api/classes/mapping";


function Mappings(props: any) {
    const auth: authenticationProvider = props?.auth

    const [selectedMapping, setSelectedMapping] = useState(-1);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [createMappingOpen, setCreateMappingOpen] = useState(false);
    const [mappingQuestion, setMappingQuestion] = useState("");
    const [mappingAnswer, setMappingAnswer] = useState("");
    const [loading, setLoading] = useState(false);


    const deleteMapping = async () => {
        console.log("deleting mapping");
        setDeleteOpen(false);
        setLoading(true);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/mappings/${selectedMapping}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                if (response.status === 200) {
                    await auth.updateMappings().then(() => {
                        console.log("successfully deleted mapping");
                    })
                }
            })
    }



    return (
        <>
            {auth.tenants?.active !== undefined && auth.context?.mappings !== undefined ? (
                <>
                    <Loading active={loading} withOverlay />
                    <DataTable rows={auth.context?.mappings} headers={[
                        {key: "question", header: "Question"},
                        {key: "text", header: "Mapping Text"}
                    ]}
                               radio
                    >
                        {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getTableProps,
                              getToolbarProps,
                              onInputChange,
                              getTableContainerProps,
                              getSelectionProps
                          }) => (
                            <TableContainer
                                title="Mappings"
                                description="Mappings allow to improve your bots performance by simply adding mapped relationships between a question and a desired answer."
                                {...getTableContainerProps()}>
                                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                                    <TableToolbarContent>
                                        <TableToolbarSearch onChange={onInputChange} persistent/>
                                        <TableToolbarMenu light>
                                            <TableToolbarAction onClick={() => setDeleteOpen(true)}>
                                                Delete
                                            </TableToolbarAction>
                                        </TableToolbarMenu>
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            <th scope={"col"} />
                                            {headers.map((header) => (
                                                <TableHeader key={header.key} {...getHeaderProps({header})}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.key} {...getRowProps({row})}>
                                                <TableSelectRow onChange={() => setSelectedMapping(row.id)} {...getSelectionProps({row})} />
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>

                    <Modal
                        open={deleteOpen}
                        danger
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onRequestClose={() => setDeleteOpen(false)}
                        onRequestSubmit={async () => await deleteMapping()
                            .then(() => setLoading(false))}
                        modalHeading={"Delete Mapping"}
                    >
                        Are you sure to delete the selected mapping?
                    </Modal>

                </>
            ) : ""}

        </>
    )
}

export default Mappings;