import React, {useEffect, useState} from 'react';
// @ts-ignore
import {
    FlexGrid,
    Header,
    HeaderName,
    Modal,
    TextInput,
    HeaderMenuButton,
    ToastNotification,
    HeaderNavigation,
    HeaderMenu,
    HeaderMenuItem,
    HeaderContainer,
    SideNavItems,
    SideNav,
    SideNavLink,
    Tag,
    HeaderGlobalBar,
    HeaderGlobalAction,
    Dropdown,
    Select,
    SelectItem,
    Row,
    Column,
    StoryContent,
    SkipToContent,
    InlineNotification,
    Tile,
    Button,
    InlineLoading,
    Toggle

} from '@carbon/react';
import { Document, Model, ChartRelationship, IbmWatsonStudio, Router, IbmCloudPakWatsonAiops, SettingsAdjust, User, Home, Events, Chat, Logout } from '@carbon/icons-react';
import authenticationProvider from "../api/authenticationProvider";
import {Tenant} from "../api/classes/tenant";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Documents from "./documents";
import {API_URL} from "../constants";
import {Toaster} from "react-hot-toast";



function Layout(props: any) {
    const navigate = useNavigate();
    const [state, setState] = useState("home");
    const auth: authenticationProvider = props?.auth
    const [isActive, setIsActive] = useState(false);
    const [createTenantOpen, setCreateTenantOpen] = useState(false);
    const [tenantName, setTenantName] = useState("");
    const [tenantType, setTenantType] = useState("factual");
    const [tenantManagerOpen, setTenantManagerOpen] = useState(auth.tenants.active === undefined);
    const [selectedTenant, setSelectedTenant] = useState(auth.tenants.tenants !== undefined ? auth.tenants.tenants[0] : {} as Tenant);
    const [chatOpen, setChatOpen] = useState(false);
    const [question, setQuestion] = useState("");
    const [isAsking, setIsAsking] = useState(false);
    const [answer, setAnswer] = useState("");
    const [similarity, setSimilarity] = useState(0.0);

    const ask = async () => {
        let q = question
        if(question.length < 1)
            return;
        setIsAsking(true);
        console.log(`requesting answer to question ${q}`)
        try {
            await fetch(`${API_URL}/ask/${auth.tenants.active?.id}?key=${auth.tenants.active?.apiKey}`, {
                method: "POST",
                body: JSON.stringify({
                    "message": q,
                })
            })
                .then((data) => data.json())
                .then(async (response) => {
                    setAnswer(response.body.context.result.answer_text)
                    setSimilarity(response.body.context.result.score)
                })
                .then(() => setIsAsking(false))
        } catch {
            setIsAsking(false);
        }
    };


    const createTenant = async () => {
        if (tenantName.length < 2) {
            return;
        }
        await fetch(`${API_URL}/auth/tenant`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${auth.user.token}`
            },
            body: JSON.stringify({
                name: tenantName,
                factual: tenantType === "factual" ? 1 : 0,
                user_id: auth.user.id
            })
        })
            .then(data => data.json())
            .then(async response => {
                if (response.status === 200) {
                    await auth.updateTenants()
                        .then(() => {
                            setCreateTenantOpen(false);
                            setTenantManagerOpen(true);
                        })
                }
            })
    }

    useEffect(() => {
        switch (state) {
            case "home":
                navigate("/");
                break;
            case "documents":
                navigate("/documents");
                break;
            case "facts":
                navigate("/facts");
                break;
            case "settings":
                navigate("/settings");
                break;
            case "mappings":
                navigate("/mappings");
                break;
            case "logging":
                navigate("/logging");
                break;
            case "predictions":
                navigate("predictions");
                break;
            default:
                navigate("/");
                break;
        }
    }, [state])

    // todo: map

    return (
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <>
                    <Header aria-label={"Header"}>
                        <HeaderMenuButton
                            aria-label={"Expand"}
                            isActive={false}
                            onClick={onClickSideNavExpand}
                        />
                        <HeaderName prefix={"NLyou"}>
                            {
                                auth.tenants?.active === undefined ? "" : (
                                    `[${auth.tenants.active.name}]`
                                )
                            }
                        </HeaderName>
                        <HeaderGlobalBar style={{ marginRight: "1rem"}}>
                            <HeaderGlobalAction aria-label={"Chat"} onClick={() => setChatOpen(true)}>
                                <Chat size={20} />
                            </HeaderGlobalAction>
                            <HeaderGlobalAction
                                aria-label={"Manage Tenants"}
                                onClick={() => setTenantManagerOpen(true)}
                            >
                                <Events size={20} />
                            </HeaderGlobalAction>
                            <HeaderGlobalAction aria-label={"Logout"}>
                                <Logout size={20} onClick={() => window.location.reload() } />
                            </HeaderGlobalAction>
                        </HeaderGlobalBar>
                        <SideNav
                            aria-label={"Navigation"}
                            expanded={true}>



                            <SideNavLink renderIcon={Home} onClick={() => setState("home")}>
                                Dashboard
                            </SideNavLink>

                            <SideNavLink renderIcon={Model} onClick={() => setState("facts")}>
                                Facts
                            </SideNavLink>


                            <SideNavLink renderIcon={Document} onClick={() => setState("documents")}>
                                Documents
                            </SideNavLink>

                            <SideNavLink renderIcon={Router} onClick={() => setState("mappings")}>
                                Mappings
                            </SideNavLink>

                            <SideNavLink renderIcon={IbmWatsonStudio} onClick={() => setState("predictions")}>
                                Predictions
                            </SideNavLink>


                            <SideNavLink renderIcon={IbmCloudPakWatsonAiops} onClick={() => setState("logging")}>
                                Logging
                            </SideNavLink>

                            <SideNavLink renderIcon={SettingsAdjust} onClick={() => setState("settings")}>
                                Settings
                            </SideNavLink>

                        </SideNav>
                    </Header>

                        <div style={{
                            margin: "4rem",
                            minWidth: "1024px",
                            position: "absolute",
                            right: "16px",
                            left: "192px"
                        }}>
                            <Outlet />
                        </div>

                        <Modal
                            open={tenantManagerOpen}
                            primaryButtonText={"Switch Tenant"}
                            secondaryButtonText={"Create Tenant"}
                            modalHeading={"Manage Tenants"}
                            hasScrollingContent
                            aria-label={"Tenant Selection"}
                            onRequestClose={() => {
                                if (auth.tenants.active !== undefined) {
                                    setTenantManagerOpen(false);
                                    return;
                                }
                            }}
                            onRequestSubmit={async () => {
                                await auth.switchTenant(selectedTenant).then(() => setTenantManagerOpen(false))

                            }}
                            onSecondarySubmit={() => {
                                setTenantManagerOpen(false);
                                setCreateTenantOpen(true);
                            }}
                        >

                            {
                                auth.tenants.tenants?.length === 0 ? (
                                    "No tenants available. Please create your first one."
                                ) : (
                                    <Select
                                        id={"tenant-selector"}
                                        labelText={"Tenant"}
                                        defaultValue={selectedTenant.id}
                                        onChange={(e) => setSelectedTenant(auth.getTenantById(e.target.value))}
                                    >
                                        {
                                            auth.tenants.tenants?.map((t: Tenant) => (
                                                <SelectItem key={t.id} value={t.id} text={t.name} />
                                            ))
                                        }
                                    </Select>
                                )
                            }

                        </Modal>

                        <Modal
                            open={createTenantOpen}
                            primaryButtonText={"Create Tenant"}
                            secondaryButtonText={"Cancel"}
                            selectorPrimaryFocus={"#new-tenant-name"}
                            onRequestClose={() => {
                                setCreateTenantOpen(false);
                                setTenantManagerOpen(true);
                            }}
                            modalHeading={"Create Tenant"}
                            onRequestSubmit={() => createTenant()}
                        >
                            <TextInput
                                id={"new-tenant-name"}
                                key={"new-tenant-name"}
                                type={"text"}
                                labelText={"Tenant Name"}
                                value={tenantName}
                                onChange={(e) => setTenantName(e.target.value)}
                                style={{
                                    marginBottom: "1rem"
                                }}
                            />

                        </Modal>


                        <Modal
                            shouldSubmitOnEnter
                            open={chatOpen}
                            primaryButtonText={"Ask"}
                            primaryButtonDisabled={isAsking}
                            secondaryButtonText={"Cancel"}
                            size={"sm"}
                            selectorPrimaryFocus={"#question"}
                            modalHeading={"Q&A"}
                            onRequestSubmit={ask}
                            onRequestClose={() => {
                                setChatOpen(false)
                                setQuestion("");
                                setAnswer("");
                            }}
                        >
                            <TextInput
                                id={"question"}
                                key={"question"}
                                labelText={"Question"}
                                placeholder={"Your Question"}
                                value={question}
                                onChange={(e: any) => setQuestion(e.target.value)}
                            />
                            {
                                answer.length > 0 ? (<Tile>
                                    {answer}
                                </Tile>) : ""
                            }
                            {
                                isAsking ? (
                                    <InlineLoading
                                       status={"active"}
                                       iconDescription={"Loading"}
                                       description={"Generating answer..."}
                                    />
                                ) : ""
                            }
                        </Modal>
                    </>
                )} />
    )
}

export default Layout;