import {API_URL} from "../constants";
import Profile from "./classes/profile";
import {Document, Metadata} from "./classes/document"
import User from "./classes/user";
import {Statistics, Tenant, Tenants} from "./classes/tenant";
import Context from "./classes/context";
import TextualPiece from "./classes/textualPiece";
import Sentence from "./classes/sentence";
import Mapping from "./classes/mapping";
import Fact from "./classes/fact";
import Glossary from "./classes/glossary";

// todo: refactor AuthenticationProvider
// -> partial reloads
// -> pagination

class AuthenticationProvider {

    user: User;
    setUser: any;
    tenants: Tenants;
    setTenants: any;
    context: Context;
    setContext: any;
    setNotification: any;

    isCompiling: boolean = false;

    testingStatistics: any;

    toast: any;



    constructor(user: User,
                setUser: any,
                tenants: Tenants,
                setTenants: any,
                context: Context,
                setContext: any,
                setNotification: any,
                toast: any) {
        this.user = user;
        this.setUser = setUser;
        this.tenants = tenants;
        this.setTenants = setTenants;
        this.context = context;
        this.setContext = setContext;
        this.setNotification = setNotification;
        this.testingStatistics = {} as {
            n_questions: number,
            n_matches: number,
            ratio: number
        };
        this.toast = toast;
    }

    hasMultiLanguageSupport = (): boolean => {
        console.log(this.tenants.active)
        return this.tenants?.active?.multiLanguage == 1;
    }


    showMessage(title: string, subtitle: string, kind: string = "success") {
        this.setNotification({
            title: title,
            subtitle: subtitle,
            kind: kind,
            visibility: true,
            timeout: 4
        })
    }

    getDocumentById = (object_id: string): Document | undefined => {
        console.log(object_id)
        console.log(this.context.documents)
        return this.context?.documents?.filter((d: Document) => d.id === object_id).at(0);
    }

    getFactById = (fact_id: number): TextualPiece | undefined =>  {
        let fact = this.context?.facts?.filter((f: TextualPiece) => f.id === fact_id).at(0);
        console.log(fact)
        return fact;
    }

    private mapTenants(data: any): Tenant[] {
        let tenants: Tenant[] = [];
        Object.values(data).forEach((t: any) => {
            console.log(`multiLanguage is ${t.multi_language}`)
            tenants.push({
                id: t.id,
                name: t.name,
                apiKey: t.api_key,
                threshold: t.threshold,
                mappingConfidence: t.mapping_confidence,
                translator: t.translator,
                language: t.language,
                multiLanguage: t.multi_language,
                members: t.members,
                statistics: {
                    totalTokensBilled: t.statistics.total_tokens_billed,
                    totalTokensBilledUSD: t.statistics.total_tokens_billed_usd,
                    totalMessages: t.statistics.total_messages,
                    tokensPerMessage: t.statistics.tokens_per_message
                } as Statistics,
                embeddingEngine: t.embedding_engine,
                oaiApiKey: t.openai_api_key,
                oaiTemperature: t.openai_temperature,
                oaiTopP: t.openai_top_p,
                oaiMaxTokens: t.openai_max_tokens,
                oaiNGroups: t.openai_n_groups,
                deeplApiKey: t.deepl_api_key,
                promptTemplate: t.prompt_template,
                protection: t.protection
            } as Tenant);
        })
        console.log(tenants)
        return tenants;
    }

    async switchTenant(tenant: Tenant) {
        this.toast.success(`You successfully changed tenancy to ${tenant.name}`)
        await this.updateDocuments(tenant).then(() => {
            this.setTenants({active: tenant, tenants: this.tenants.tenants, isReady: true} as Tenants);
        }).then(async () => {
            await this.updateFacts(tenant);
        }).then(async () => {
            await this.updateMappings(tenant);
        });
    }

    getTenantById(id: number): Tenant {
        // eslint-disable-next-line eqeqeq
        return this.tenants.tenants.filter((t: Tenant) => t.id == id)[0];
    }

    getGlossaryEntriesByGlossaryId = (glossaryId: number): TextualPiece[] => {
        let entries = this.context?.glossaries?.filter((g: Glossary) => g.base.id == glossaryId).at(0)?.entries;
        return entries === undefined ? [] : entries;
    }


    async register(email: string, password: string) {
        console.log(`creating user ${email}`)
        await fetch(`${API_URL}/auth/register`, {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
            .then(data => data.json())
            .then(response => {
                console.log(`received response ${response}`);
            })
    }

    async updateTenants(user: User | undefined = undefined) {
        if (user === undefined) {
            user = this.user;
        }
        console.log(`requesting tenants for user with id ${user.id}`)
        fetch(`${API_URL}/auth/user/${user.id}/tenants`, {
            method: "GET",
            headers: {
                Authorization: `Bearerr ${user.token}`
            }
        })
            .then(data => data.json())
            .then(response => this.mapTenants(response.body.tenants))
            .then(tenants => {
                this.setTenants({active: this.tenants.active, tenants: tenants} as Tenants)
            })
    }

    async updateActiveTenant() {
        if (this.tenants.active === undefined) {
            return;
        }
        await fetch( `${API_URL}/auth/tenant/${this.tenants.active.id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                if (response.status === 200) {
                    this.tenants.active = this.mapTenants(response.body.tenants)[0]
                    console.log("updated active tenant successfully");
                }
            })
    }


    async updateContext(kind: string, data: any) {
        console.log(`updating context for kind ${kind} to ${JSON.stringify(data)}`);
        if (kind === "documents") {
            this.setContext((prevState: any) => Object.assign(prevState, {documents: data}))
        }
        if (kind === "model_questions") {
            this.setContext((prevState: any) => Object.assign(prevState, {model_questions: data}))
        }
        if (kind === "tenant_questions") {
            this.setContext((prevState: any) => Object.assign(prevState, {tenant_questions: data}))
        }
        if (kind === "facts") {
            this.setContext((prevState: any) => Object.assign(prevState, {facts: data}))
        }
        if (kind === "mappings") {
            this.setContext((prevState: any) => Object.assign(prevState, {mappings: data}))
        }
        if (kind === "glossaries") {
            this.setContext((prevState: any) => Object.assign(prevState, {glossaries: data}))
        }
    }

    async updateQuestions(tenant: Tenant | undefined = undefined) {
        if (tenant === undefined) {
            tenant = this.tenants.active;
        }
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/model/questions`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => await this.updateContext("model_questions", response.body.questions))
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/questions`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                // todo: think about better solution for statistics
                // todo: => ref partial reloads (+ skeletons)
                this.testingStatistics = {
                    n_questions: response.body.statistics.n_questions,
                    n_matches: response.body.statistics.n_matches,
                    ratio: response.body.statistics.ratio
                }
                await this.updateContext("tenant_questions", this.mapTextualPieces(response.body.questions))
            })
    }


    private mapTextualPieces(data: any): TextualPiece[] {
        let pieces: TextualPiece[] = [];
        Object.values(data).forEach((t: any) => {
            pieces.push({
                id: t.id,
                text: t.text,
                text_clear: t.clear_text,
                answer: t.predicted_answer,
                enabled: t.enabled,
                locked_answer: t.locked_answer,
                match: t.match
            } as TextualPiece)
        })
        return pieces;
    }

    // todo: refactor flattening
    // todo: refactor ids (datatable requires string)

    private mapGlossary(data: any): Glossary[] {
        let glossaries: Glossary[] = [];
        console.log(data)
        Object.values(data).forEach((t: any) => {
            let base = {
                id: t.glossary.id.toString(),
                text: t.glossary.text,
                base: {
                    id: t.glossary.id,
                    text: t.glossary.text
                } as TextualPiece,
                entries: []
            } as Glossary
            console.log(base)
            Object.values(t.entries).forEach((e: any) => {
                base.entries.push({
                    id: e.id,
                    text: e.text
                } as TextualPiece)
            })
            glossaries.push(base);
        })
        return glossaries;
    }

    private mapMappings(data: any): Mapping[] {
        let mappings: Mapping[] = [];
        Object.values(data).forEach((t: any) => {
            mappings.push({
                id: t.id,
                question: t.question,
                text: t.text
            } as Mapping)
        })
        return mappings;
    }

    async updateFacts(tenant: Tenant | undefined = undefined) {
        if (tenant === undefined) {
            tenant = this.tenants.active;
        }
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/facts`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => await this.updateContext("facts", this.mapTextualPieces(response.body.facts)))
    }

    async updateGlossary(tenant: Tenant | undefined = undefined) {
        if (tenant === undefined) {
            tenant = this.tenants.active;
        }
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/glossary`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                await this.updateContext("glossaries", this.mapGlossary(response.body.glossaries))
            })
    }

    async updateMappings(tenant: Tenant | undefined = undefined) {
        if (tenant === undefined) {
            tenant = this.tenants.active;
        }
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/mappings`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => await this.updateContext("mappings", this.mapMappings(response.body.mappings)))
    }

    async updateDocuments(tenant: Tenant | undefined = undefined) {
        if (tenant === undefined) {
            tenant = this.tenants.active;
        }
        await fetch(`${API_URL}/auth/tenant/${tenant?.id}/documents?filter=src`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${this.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => await this.updateContext("documents", this.mapDocuments(response.body.objects)))
    }

    // todo: move mappers

    private mapDocuments(data: any): Document[] {
        console.log(data);
        let documents: Document[] = [];
        Object.values(data).forEach((t: any) => {
            let metadata = {
                updated: t.metadata.updated,
                link: t.metadata.link,
                name: t.metadata?.name,
                objectId: t.blob.split("_")[1],
                file_name: t.metadata?.file_name
            } as Metadata;
            let document = {
                id: t.blob.split("_")[t.blob.split("_").length - 1],
                name: t.blob.split("/")[t.blob.split("/").length - 1],
                metadata: metadata,
                updated: t.metadata.updated,
                link: t.metadata.link
            } as Document
            if (metadata.file_name != undefined) {
                document.name = metadata.file_name;
            }
            documents.push(document);
        })
        return documents;
    }


    private mapUser(data: any): User {
        let user = {} as User;
        user.id = data.auth.user.id;
        user.email = data.auth.user.email;
        user.token = data.auth.token;
        return user;
    }

    async login(username: string, password: string) {
        console.log(`login requested for user ${username}`);
        await fetch(`${API_URL}/auth/login`, {
            method: "POST",
            body: JSON.stringify({
                "user_email": username,
                "user_password": password
            })
        })
            .then(data => data.json())
            .then(async response => {
                if (response.status === 200) {
                    this.toast.success("Successfully logged in");
                    let user = this.mapUser(response.body);
                    this.setUser(user);
                    await this.updateTenants(user).then(() => this.showMessage("Success", "Your have successfully signed in."));
                } else {
                    this.toast.error("Login failed. Check your credentials.")
                    return false;
                }
        });
        return false;
    }

}


export default AuthenticationProvider;