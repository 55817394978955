import React, {ReactElement, ReactNode, useState} from 'react';
// @ts-ignore
import { FlexGrid, Header, HeaderName, Modal, TextInput, ToastNotification, InlineNotification } from '@carbon/react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import './App.css';
import Login from "./views/login";
import authenticationProvider from "./api/authenticationProvider";
import User from "./api/classes/user";
import {Tenants} from "./api/classes/tenant";
import Context from "./api/classes/context";
import Home from "./views/home";
import Layout from "./views/layout";
import Documents from "./views/documents";
import Settings from "./views/settings";
import Facts from "./views/facts";
import Mappings from "./views/mappings";
import {Toaster, useToaster} from "react-hot-toast";
import toast from 'react-hot-toast';
import ViewLogging from "./views/logging";
import ViewPredictions from "./views/predictions";


function App() {
    // todo: important: high level container for loading, notifications etc.
    // todo: implement fully-working routing e.g. /facts/{fact_id:int}/edit
    //console.log = function() {}

    const [user, setUser] = useState({} as User);
    const [notification, setNotification] = useState({
        title: "Notification",
        subtitle: "Default Message",
        kind: "info",
        visible: false,
        timeout: 4
    })
    const [tenants, setTenants] = useState({} as Tenants)
    const [context, setContext] = useState({} as Context)
    const auth = new authenticationProvider(user, setUser, tenants, setTenants, context, setContext, setNotification, toast);

    function AuthenticationProvider({ children }: { children: React.ReactNode }) {
        const component: React.ReactNode = auth.user.token != null ? children : <Login auth={auth}/>
        return (
            <div>
                {component}
                <Toaster />
            </div>
        )
    }

  return (
      <AuthenticationProvider>
          <Routes>
              <Route path={"/"} element={<Layout auth={auth} />}>
                  <Route index element={<Home auth={auth} />} />
                  <Route path={"documents"} element={<Documents auth={auth} /> } />
                  <Route path={"facts"} element={<Facts auth={auth}  />}/>
                  <Route path={"mappings"} element={<Mappings auth={auth} />} />
                  <Route path={"settings"} element={<Settings auth={auth} />} />
                  <Route path={"logging"} element={<ViewLogging auth={auth} />} />
                  <Route path={"predictions"} element={<ViewPredictions auth={auth} />} />
              </Route>
          </Routes>
          <NotificationContainer />
      </AuthenticationProvider>
  );

}

export default App;
