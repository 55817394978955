import React, {useState} from 'react';
// @ts-ignore
import {
    FlexGrid,
    Header,
    HeaderName,
    Modal,
    TextInput,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarMenu,
    TableToolbarSearch,
    TableToolbarAction,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableHeader,
    TableToolbarContent,
    Button,
    TableSelectRow,
    FileUploader,
    Loading
} from '@carbon/react';
import authenticationProvider from "../api/authenticationProvider";
import {API_URL} from "../constants";


function Documents(props: any) {
    const auth: authenticationProvider = props?.auth
    console.log(auth.context?.documents)

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState("edit");
    const [compileOpen, setCompileOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState("");
    const [deleteOpen, setDeleteOpen] = useState(false);

    const upload = async (file: File, tenantId: number | undefined) => {
        console.log(`uploading file ${file} for tenant ${tenantId}`)
        const formData = new FormData();
        formData.append("file", file);
        await fetch(`${API_URL}/auth/tenant/${tenantId}/documents`, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(async response => await response.json())
            .then(async () => {
                await auth.updateDocuments();
            });
    }

    const deleteDocument = async (object_id: string) => {
        console.log(`deleting object with id ${object_id}`);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/documents/${object_id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                await auth.updateDocuments().then(() => {
                    console.log(`deletion request finished with status ${response.status}`);
                    if (response.status === 200) console.log("deleted object successfully");
                });
            })
    }

    const compileModel = async () => {
        console.log(`requested compilation`);
        fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/compile`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
    };

    return (
        <>
            {auth.tenants?.active !== undefined ? (
                <>
                    <DataTable rows={auth.context?.documents} headers={[
                        {key: "name", header: "Document Name"}
                    ]}
                               radio
                    >
                        {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getTableProps,
                              getToolbarProps,
                              onInputChange,
                              getTableContainerProps,
                              getSelectionProps
                          }) => (
                            <TableContainer
                                title="Documents"
                                description="Upload your documents migo should be able to know."
                                {...getTableContainerProps()}>
                                <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                                    <TableToolbarContent>
                                        <TableToolbarSearch onChange={onInputChange} persistent/>
                                        <TableToolbarMenu light>
                                            <TableToolbarAction onClick={() => setDeleteOpen(true)}>
                                                Delete
                                            </TableToolbarAction>
                                            <TableToolbarAction
                                                onClick={() => window.open(auth.getDocumentById(selectedDocument)?.link)}
                                            >
                                                Download
                                            </TableToolbarAction>
                                        </TableToolbarMenu>
                                        {
                                            <Button
                                                kind={"secondary"}
                                                onClick={() => setCompileOpen(true)}
                                            >
                                                Extract
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => setUploadModalOpen(true)}
                                        >
                                            Upload
                                        </Button>
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            <th scope={"col"} />
                                            {headers.map((header) => (
                                                <TableHeader key={header.key} {...getHeaderProps({header})}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow key={row.key} {...getRowProps({row})}>
                                                <TableSelectRow onChange={() => setSelectedDocument(row.id)} {...getSelectionProps({row})} />
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DataTable>

                    <Modal
                        passiveModal
                        open={uploadModalOpen}
                        onRequestClose={() => {
                            setUploadModalOpen(false);
                            setUploadStatus("edit");
                        }}
                        modalHeading={"Upload Document"}
                    >
                        <FileUploader
                            labelDescription={"Only .pdf files are supported."}
                            multiple={false}
                            filenameStatus={uploadStatus}
                            accept={[".pdf"]}
                            buttonLabel={"Select File"}
                            onChange={async (e) => {
                                setUploadStatus("uploading")
                                await upload(e.target.files![0], auth.tenants?.active?.id).then(() => {
                                    setUploadStatus("complete");
                                })
                            }}
                        />
                    </Modal>


                    <Modal
                        primaryButtonText={"Extract"}
                        secondaryButtonText={"Cancel"}
                        onRequestClose={() => setCompileOpen(false)}
                        open={compileOpen}
                        onRequestSubmit={() => {
                            setCompileOpen(false);
                            compileModel();
                        }}
                    >
                        Are you sure that you want to extract your document's content as facts?
                    </Modal>

                    <Modal
                        open={deleteOpen}
                        danger
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onRequestClose={() => setDeleteOpen(false)}
                        onRequestSubmit={async () => {
                            await deleteDocument(selectedDocument)
                                .then(() => setDeleteOpen(false));
                        }}
                        modalHeading={"Delete Document"}
                    >
                        Are you sure to delete the selected document?
                    </Modal>

                </>
            ) : ""}

        </>
    )
}

export default Documents;