import React, {useEffect, useState} from 'react';
import { NotificationContainer } from 'react-notifications';
// @ts-ignore
import { FlexGrid, Header, HeaderName, Modal, TextInput } from '@carbon/react';
import authenticationProvider from "../api/authenticationProvider";
import Cookies from 'js-cookie';

function Login(props: any) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginOpen, setLoginOpen] = useState(true);
    const [registerOpen, setRegisterOpen] = useState(false);
    const auth: authenticationProvider = props?.auth


    return (
        <FlexGrid>
            <Modal
                open={loginOpen}
                modalHeading="Login"
                primaryButtonText="Login"
                secondaryButtonText="Sign Up"
                shouldSubmitOnEnter
                onRequestSubmit={async () => await auth.login(email, password)}
                onSecondarySubmit={() => {
                    setRegisterOpen(true);
                    setLoginOpen(false);
                }}
                >

                <TextInput
                    data-modal-primary-focus
                    id="email"
                    labelText="Email Address"
                    placeholder="john@doe.com"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    style={{ marginBottom: "1rem" }}
                />

                <TextInput
                    data-modal-primary-focus
                    id="password"
                    labelText="Password"
                    placeholder=""
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    type={"password"}
                />

            </Modal>

            <Modal
                open={registerOpen}
                modalHeading={"Create Account"}
                primaryButtonText={"Create"}
                secondaryButtonText={"Cancel"}
                onRequestSubmit={async () => {
                    await auth.register(email, password).then(async () => await auth.login(email, password))
                }}
                onRequestClose={() => {
                    setRegisterOpen(false);
                    setLoginOpen(true);
                }}
            >
                <TextInput
                    data-modal-primary-focus
                    id="new-email"
                    labelText="Email Address"
                    placeholder="john@doe.com"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    style={{ marginBottom: "1rem" }}
                />

                <TextInput
                    data-modal-primary-focus
                    id="new-password"
                    labelText="Password"
                    placeholder=""
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    type={"password"}
                />

            </Modal>
        </FlexGrid>
    )
}

export default Login;