import React, {useState} from 'react';
import { NotificationManager } from 'react-notifications';
// @ts-ignore
import {
    FlexGrid,
    Header,
    Column,
    HeaderName,
    Select,
    SelectItem,
    Modal,
    TextInput,
    TextArea,
    Tile,
    Slider,
    Toggle,
    Grid,
    Button,
    CopyButton,
    Tooltip,
    Tag,
    Loading,
    Row,
    IconButton
} from '@carbon/react';
import {Information, Subtract, Add} from "@carbon/icons-react";
import authenticationProvider from "../api/authenticationProvider";
import {API_URL} from "../constants";
import User from "../api/classes/user";

function Settings(props: any) {
    const auth: authenticationProvider = props?.auth

    const [apiOpen, setApiOpen] = useState(false);
    const [manageMembersOpen, setManageMembersOpen] = useState(false);
    const [manageUserOpen, setManageUserOpen] = useState(false);
    const [deleteTenantOpen, setDeleteTenantOpen] = useState(false);
    const [targetUser, setTargetUser] = useState("")
    const [addMemberOpen, setAddMemberOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [toggleStatus, setToggleStatus] = useState(auth.tenants?.active?.multiLanguage == 1);
    const [updateEmbeddingOpen, setUpdateEmbeddingOpen] = useState(false);
    const [embeddingEngine, setEmbeddingEngine] = useState(auth.tenants?.active?.embeddingEngine);
    const [loading, setLoading] = useState(false);
    const [tenantName, setTenantName] = useState(auth.tenants?.active?.name);
    const [oaiApiKey, setOaiApiKey] = useState(auth.tenants?.active?.oaiApiKey);
    const [deeplApiKey, setDeeplApiKey] = useState(auth.tenants?.active?.deeplApiKey);
    const [promptTemplate, setPromptTemplate] = useState(auth.tenants?.active?.promptTemplate);


    const updateTextFields = async () => {
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            },
            body: JSON.stringify({
                "name": tenantName,
                "deepl_api_key": deeplApiKey,
                "openai_api_key": oaiApiKey,
                "prompt_template": promptTemplate
            })
        })
            .then(data => {
                if (data.status == 200) {
                    auth.toast.success("Successfully saved settings");
                } else {
                    auth.toast.error("Failed to update settings");
                }
            })
    }


    const updateParameter = async (key: string, value: any) => {
        console.log(value)
        console.log(`updating parameter with key ${key} to value ${value}`);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}?key=${key}&value=${value}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => {
                if (data.status == 200) {
                    auth.toast.success(`Successfully patched parameter ${key} to value ${value}`);
                } else {
                    auth.toast.error("Failed to patch parameter");
                }
                return data.json()
            })
            .then(async response => {
                console.log(`patched parameter to value "${value}" successfully`);

            })
    }

    const deleteMember = async (email: string) => {
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/members?email=${email}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then((data) => data.json())
            .then(async (response) => {
                if (response.status === 200) {
                    await auth.updateActiveTenant();
                }
            })
    }

    const deleteTenant = async () => {
        console.log("deleting tenant")
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then((data) => data.json())
            .then(async (response) => {
                if (response.status === 200)
                    window.location.reload();
            })
    }

    const reindexFacts = async () => {
        setLoading(true);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/facts`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => {
                return data.json();
            })
            .then(async (response) => {
                console.log(`reindexing facts returned with status code ${response.status}`)
                setLoading(false)
            })
    }


    const addMember = async (email: string) => {
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/members`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            },
            body: JSON.stringify({
                email: email
            })
        })
            .then((data) => data.json())
            .then(async (response) => {
                if (response.status === 200) {
                    await auth.updateActiveTenant().then(() => console.log("successfully added member"));
                }
                if (response.status === 404) {
                    console.log("user not found");
                }
                if (response.status === 409) {
                    console.log("user already exists");
                }
            })
        setAddMemberOpen(false);
        setManageMembersOpen(true);
    }

    auth.updateActiveTenant();

    return (
        <>
            <Loading active={loading} withOverlay />
            <div style={{marginBottom: "2rem"}}>
                <Button
                    style={{marginRight: "1rem"}}
                    onClick={() => setManageMembersOpen(true)}
                >
                    Manage Members
                </Button>
                <Button
                    kind={"secondary"}
                    onClick={() => setApiOpen(true)}
                >
                    Show API Endpoint
                </Button>
                <div
                    style={{
                        display: "inline",
                        float: "right"
                    }}
                >
                    <Button
                        style={{marginRight: "1rem"}}
                        kind={"secondary"}
                        onClick={() => {
                            setEmbeddingEngine(auth.tenants?.active?.embeddingEngine);
                            setUpdateEmbeddingOpen(true);
                        }}
                    >
                        Reindex Embeddings
                    </Button>
                    <Button
                        kind={"danger"}
                        onClick={() => setDeleteTenantOpen(true)}
                    >
                        Delete Tenant
                    </Button>
                </div>

            </div>


            <Tile style={{marginBottom: "2rem"}}>
                <TextInput
                    labelText={"Tenant Name"}
                    value={tenantName}
                    onEnter={() => alert("test")}
                    onChange={(e) => setTenantName(e.target.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <TextInput
                    labelText={"Translator API Key"}
                    value={deeplApiKey}
                    onChange={(e) => setDeeplApiKey(e.target.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <TextInput
                    labelText={"OpenAI API Key"}
                    value={oaiApiKey}
                    onChange={(e) => setOaiApiKey(e.target.value)}
                />
            </Tile>


            <Tile style={{marginBottom: "2rem"}}>
                <TextArea
                    labelText={"Prompt Template"}
                    value={promptTemplate}
                    onChange={(e) => setPromptTemplate(e.target.value)}
                    helperText={"Available placeholders are: {question.original}, {question.translation}, {facts.original}, (optional:{params.*})"}
                />
            </Tile>


            <div style={{marginBottom: "2rem"}}>
                <Button onClick={
                    updateTextFields
                }>Save</Button>
            </div>

            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    labelText={"OpenAI Temperature"}
                    id={"oai_temperature"}
                    value={auth.tenants?.active?.oaiTemperature}
                    min={0}
                    max={1}
                    step={0.01}
                    noValidate
                    onRelease={(e) => updateParameter("openai_temperature", e.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    labelText={"OpenAI Top P"}
                    id={"oai_top_p"}
                    value={auth.tenants?.active?.oaiTopP}
                    min={0}
                    max={1}
                    step={0.01}
                    noValidate
                    onRelease={(e) => updateParameter("openai_top_p", e.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    labelText={"OpenAI Max Tokens"}
                    id={"oai_max_tokens"}
                    value={auth.tenants?.active?.oaiMaxTokens}
                    min={128}
                    max={2048}
                    step={64}
                    noValidate
                    onRelease={(e) => updateParameter("openai_max_tokens", e.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    labelText={"N Considered Fact Groups"}
                    id={"oai_n_groups"}
                    value={auth.tenants?.active?.oaiNGroups}
                    min={0}
                    max={10}
                    step={1}
                    noValidate
                    onRelease={(e) => updateParameter("openai_n_groups", e.value)}
                />
            </Tile>



            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    labelText={"Semantic Threshold"}
                    id={"threshold"}
                    value={auth.tenants?.active?.threshold}
                    min={0}
                    max={300}
                    step={1.0}
                    noValidate
                    onRelease={(e) => updateParameter("threshold", e.value)}

                />
            </Tile>


            <Tile style={{marginBottom: "2rem"}}>
                <Slider
                    id={"mappingConfidence"}
                    labelText={"Mapping Confidence"}
                    value={auth.tenants?.active?.mappingConfidence}
                    min={0}
                    max={200}
                    step={1.0}
                    noValidate
                    
                    onRelease={(e) => updateParameter("mapping_confidence", e.value)}
                />
            </Tile>

            <Tile style={{marginBottom: "2rem"}}>
                <Toggle
                    id={"multi-language-support"}
                    labelText={"Multi-Language Support"}
                    labelA={"Disabled"}
                    labelB={"Enabled"}
                    toggled={toggleStatus}
                    onToggle={async (e) => {
                        await updateParameter("multi_language", e ? 1 : 0)
                            .then(async () => await auth.updateActiveTenant())
                            .then(async () => setToggleStatus(auth.tenants?.active?.multiLanguage == 1))
                    }}
                />
            </Tile>


            <Modal
                open={updateEmbeddingOpen}
                modalHeading={"Updating Embedding Engine"}
                primaryButtonText={"Update"}
                secondaryButtonText={"Cancel"}
                onRequestClose={() => setUpdateEmbeddingOpen(false)}
                onRequestSubmit={async () => {
                    await updateParameter("embedding_engine", embeddingEngine).then(async () => {
                        setUpdateEmbeddingOpen(false);
                        await reindexFacts().then(() => setLoading(false));
                    });
                }}
            >
                Are you sure that you want to update or reindex your embeddings?
            </Modal>

            <Modal
                open={apiOpen}
                modalHeading={"API Endpoint"}
                passiveModal
                onRequestClose={() => setApiOpen(false)}
            >
                <TextInput
                    labelText={"Endpoint"}
                    value={`${API_URL}/ask/${auth.tenants?.active?.id}?key=${auth.tenants?.active?.apiKey}`}
                    disabled
                    style={{marginBottom: "1rem"}}
                />
                <TextInput
                    labelText={"Example Payload"}
                    value='{"message": "ENTER YOUR QUESTION HERE"}'
                    disabled
                />
            </Modal>

            <Modal
                open={manageMembersOpen}
                modalHeading={"Manage Members"}
                onRequestClose={() => setManageMembersOpen(false)}
                onRequestSubmit={() => {
                    setManageMembersOpen(false);
                    setAddMemberOpen(true);
                }}
                primaryButtonText={"Add Member"}
                secondaryButtonText={"Cancel"}
            >
                <Tile>
                    Active Members
                    <br />
                    <br />
                    {
                        auth.tenants?.active?.members.filter((email: string) => email !== auth.user.email).map((email: string) => (
                            <Tag type={"purple"} size={"md"} onClick={() => {
                                setManageUserOpen(true);
                                setTargetUser(email);
                            }}>
                                {email}
                            </Tag>
                        ))
                    }
                </Tile>
            </Modal>

            <Modal
                open={addMemberOpen}
                modalHeading={"Add Member"}
                primaryButtonText={"Add"}
                secondaryButtonText={"Cancel"}
                onRequestClose={() => setAddMemberOpen(false)}
                onRequestSubmit={async () => addMember(email)}
            >
                <TextInput
                    labelText={"Email"}
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    style={{marginBottom: "1rem"}}
                />
            </Modal>

            <Modal
                open={deleteTenantOpen}
                danger
                modalHeading={"Delete Tenant"}
                primaryButtonText={"Delete Tenant"}
                secondaryButtonText={"Cancel"}
                onRequestClose={() => setDeleteTenantOpen(false)}
                onRequestSubmit={async () => await deleteTenant().then(() => setDeleteTenantOpen(false))}
            >
                Are you sure that you what to delete <strong>{auth.tenants?.active?.name}</strong>?
            </Modal>

            <Modal
                open={manageUserOpen}
                danger
                modalHeading={"Manage User"}
                primaryButtonText={"Remove User"}
                secondaryButtonText={"Cancel"}
                onRequestClose={() => setManageUserOpen(false)}
                onRequestSubmit={async() => {
                    await deleteMember(targetUser).then(() => {
                        setManageUserOpen(false);
                    })
                }}
            >
                Are you sure that you want delete {targetUser} from {auth.tenants?.active?.name}?
            </Modal>
        </>
    )
}

export default Settings;