// @ts-nocheck
// @ts-ignore
import React, {useRef, useState} from 'react';
import { Renew, Download, Save, TrashCan } from '@carbon/icons-react';
import {
    FlexGrid,
    Header,
    HeaderName,
    Modal,
    TextInput,
    DataTable,
    TableExpandedRow,
    Pagination,
    OverflowMenu,
    OverflowMenuItem,
    FileUploader,
    IconButton,
    TableExpandRow,
    TableContainer,
    TableBatchAction,
    TableBatchActions,
    TableToolbar,
    TableToolbarMenu,
    TextArea,
    TableToolbarSearch,
    TableToolbarAction,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableSelectAll,
    TableExpandHeader,
    TableCell,
    TableHeader,
    TableToolbarContent,
    Button,
    TableSelectRow,
    Loading
} from '@carbon/react';
import { Reset} from '@carbon/icons-react';

import { Editor } from '@tinymce/tinymce-react';
import authenticationProvider from "../api/authenticationProvider";
import {API_URL} from "../constants";
import {create} from "domain";
import Fact from "../api/classes/fact";
import TextualPiece from "../api/classes/textualPiece";
import {Link} from "react-router-dom";


function Facts(props: any) {
    const auth: authenticationProvider = props?.auth

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState("edit");
    const [selectedFact, setSelectedFact] = useState({} as TextualPiece);
    const [deleteOpen, setDeleteOpen] = useState({
        show: false,
        selectedRows: []
    });
    const [createFactOpen, setCreateFactOpen] = useState(false);
    const [editorMode, setEditorMode] = useState("create");
    const editorRef = useRef(null); //ts-ignore
    const [createMappingOpen, setCreateMappingOpen] = useState(false);
    const [mappingQuestion, setMappingQuestion] = useState("");
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10
    })
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState(auth.context.facts);

    const upload = async (file: File, tenantId: number | undefined) => {
        console.log(`uploading file ${file} for tenant ${tenantId}`)
        const formData = new FormData();
        formData.append("file", file);
        await fetch(`${API_URL}/auth/tenant/${tenantId}/import_facts`, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(async response => await response.json())
            .then(async () => {
                await auth.updateDocuments();
            })
            .then(() => auth.toast.success("Successfully imported facts"));
    }

    const deleteDocument = async (object_id: string) => {
        console.log(`deleting object with id ${object_id}`);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/documents/${object_id}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                await auth.updateDocuments().then(() => {
                    console.log(`deletion request finished with status ${response.status}`);
                    if (response.status === 200) console.log("deleted object successfully");
                });
            })
    }


    const deleteFact = async () => {
        let selectedRows = deleteOpen.selectedRows;
        console.log(`deleting ${selectedRows.length} facts`)
        setLoading(true);
        for (let i = 0; i < selectedRows.length; i++) {
            let row = selectedRows[i];
            console.log(`deleting fact with id ${row.id}`)
            await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/facts/${row.id}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${auth.user.token}`
                }
            })
                .then(data => data.json())
                .then(async response => {
                    console.log(`deleting fact ${row.id} returned with status code ${response.status}`)
                })
        }
    }

    const updateFact = async (fact: TextualPiece) => {
        console.log(`updating fact ${fact.text}`)
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/facts/${fact.id}`, {
            method: "PATCH",
            body: JSON.stringify({
                text: fact.text
            }),
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                console.log(`updating fact returned status code ${response.status}`)
                await auth.updateFacts();
            })
    }

    const createFact = async (fact: string) => {
        console.log(`adding fact ${fact}`)
        fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/facts`, {
            method: "POST",
            body: JSON.stringify({
                text: fact
            }),
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            }
        })
            .then(data => data.json())
            .then(async response => {
                console.log(`adding fact returned status code ${response.status}`)
                await auth.updateFacts();
            })
    }

    const createMapping = async () => {
        if (mappingQuestion.length <= 1) return;
        setLoading(true);
        await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/mappings`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${auth.user.token}`
            },
            body: JSON.stringify({
                question: mappingQuestion,
                fact_id: deleteOpen.selectedRows[0].id
            })
        })
            .then(data => data.json())
            .then(async response => {
                if (response.status === 200) console.log("successfully added mapping");
                await auth.updateMappings().then(() => {
                    setMappingQuestion("");
                });
            })
    }

    const enableFacts = async (selectedRows: any, disable: boolean = false) => {
        console.log(`enabling ${selectedRows.length} rows`);
        console.log(selectedRows)
        setLoading(true);
        for (let i = 0; i < selectedRows.length; i++) {
            let row = selectedRows[i];
            console.log(`enabling row with id ${row.id}`);
            await fetch(`${API_URL}/auth/tenant/${auth.tenants?.active?.id}/facts/${row.id}`, {
                method: "PATCH",
                body: JSON.stringify({
                    enabled: disable ? 0 : 1
                }),
                headers: {
                    "Authorization": `Bearer ${auth.user.token}`
                }
            })
                .then(data => data.json())
                .then(async response => {
                    console.log(`enabling fact with id ${row.id} returned status code ${response.status}`);
                })
        }
    }


    return (
        <>

            {auth.tenants?.active !== undefined && auth.context?.facts !== undefined ? (
                createFactOpen ? (
                        <>
                            <div style={{
                                marginBottom: "1rem"
                            }}>
                                <Button
                                    style={{
                                     marginRight: "1rem"
                                    }}
                                    onClick={async () => {
                                        if (editorMode === "create")
                                            await createFact(tinymce.activeEditor.getContent())
                                                .then(async () => await auth.updateFacts())
                                                .then(() => setCreateFactOpen(false))
                                                .then(() => auth.toast.success("Successfully created new fact"));
                                        if (editorMode === "edit")
                                            await updateFact({
                                                id: selectedFact.id,
                                                text:  tinymce.activeEditor.getContent()
                                            } as TextualPiece).then(() => setCreateFactOpen(false));
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => setCreateFactOpen(false)}
                                    kind={"secondary"}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={selectedFact.text}
                                selector={"#fact"}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    branding: false,
                                    contextmenu: "link image",
                                    plugins: "link image table lists",
                                    toolbar: 'undo redo removeformat',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </>
                    ) : (
                <>
                    <Loading active={loading} withOverlay />
                    <DataTable
                        rows={rows}
                        headers={[
                            {key: "text_clear", header: "Fact"},
                            {key: "enabled", header: "Status"}
                        ]}
                    >
                        {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getSelectionProps,
                              getToolbarProps,
                              getBatchActionProps,
                              onInputChange,
                              selectedRows,
                              getTableProps,
                              getTableContainerProps,
                          }) => {
                            const batchActionProps = getBatchActionProps();

                            return (
                                <TableContainer
                                    title={`Facts (${auth.context?.facts?.length})`}
                                    {...getTableContainerProps()}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableBatchActions {...batchActionProps}>
                                            <TableBatchAction
                                                tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                                renderIcon={TrashCan}
                                                onClick={() => {
                                                    setDeleteOpen({show: true, selectedRows: selectedRows})
                                                }}
                                            >
                                                Delete
                                            </TableBatchAction>
                                            <TableBatchAction
                                                tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                                onClick={async () => await enableFacts(selectedRows, true)
                                                    .then(async () => await auth.updateFacts())
                                                    .then(() => setLoading(false))}
                                                renderIcon={Download}>
                                                Disable
                                            </TableBatchAction>
                                            <TableBatchAction
                                                tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                                onClick={async () => await enableFacts(selectedRows)
                                                    .then(async () => await auth.updateFacts()
                                                        .then(() => setLoading(false)))}
                                                renderIcon={Save}>
                                                Enable
                                            </TableBatchAction>
                                            {
                                                selectedRows.length === 1 ? (
                                                    <TableBatchAction
                                                        tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                                        onClick={() => {
                                                            // todo: move selectedRows to own state
                                                            setDeleteOpen({...deleteOpen, selectedRows: selectedRows})
                                                            setCreateMappingOpen(true)
                                                        }}
                                                        renderIcon={Save}>
                                                        Map
                                                    </TableBatchAction>
                                                ) : (<></>)
                                            }
                                            {
                                                selectedRows.length === 1 ? (
                                                    <TableBatchAction
                                                        tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                                        onClick={() => {
                                                            // todo: move selectedRows to own state
                                                            setSelectedFact(auth.getFactById(selectedRows[0].id));
                                                            setEditorMode("edit");
                                                            setCreateFactOpen(true);
                                                        }}
                                                        renderIcon={Save}>
                                                        Edit
                                                    </TableBatchAction>
                                                ) : (<></>)
                                            }
                                        </TableBatchActions>
                                        <TableToolbarContent
                                            aria-hidden={batchActionProps.shouldShowBatchActions}>
                                            <TableToolbarSearch
                                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                                onChange={(e) => setRows(
                                                    auth.context.facts?.filter((f) => f.text.toLowerCase().includes(e.target.value.toLowerCase()))
                                                )}
                                            />
                                            <IconButton
                                                size={"lg"}
                                                kind="secondary"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    await auth.updateFacts()
                                                        .then(() => setLoading(false))
                                                }}
                                            >
                                                <Reset />
                                            </IconButton>
                                            <Button
                                                onClick={() => setUploadModalOpen(true)}
                                                style={{backgroundColor: "#FF744F"}}
                                            >
                                                Import
                                            </Button>
                                            <Button
                                                onClick={() => window.open(`${API_URL}/download/${auth.tenants?.active?.id}/all_facts?apiKey=${auth.tenants?.active?.apiKey}`)}
                                                kind={"secondary"}
                                            >
                                                Export
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSelectedFact({} as Fact);
                                                    setEditorMode("create");
                                                    setCreateFactOpen(true);
                                                }}
                                            >
                                                Create Fact
                                            </Button>
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()}>
                                        <TableHead >
                                            <TableRow>
                                                <TableSelectAll {...getSelectionProps()} />
                                                {headers.map((header, i) => (
                                                    <TableHeader key={i} {...getHeaderProps({ header, isSortable: true })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                <TableRow key={i} {...getRowProps({ row })}>
                                                    <TableSelectRow
                                                        {...getSelectionProps({ row })}
                                                    />
                                                    {row.cells.map((cell) => (
                                                        <TableCell
                                                            key={cell.id}>
                                                            {
                                                                cell.id.includes("enabled") ? (
                                                                    cell.value == 1 ? (
                                                                        <div style={{
                                                                        color: "green"
                                                                    }}>Enabled</div>
                                                                    ) : (<div style={{color: "red"}}>Disabled</div>)
                                                                ) : cell.value
                                                            }
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }}
                    </DataTable>

                    <Modal
                        open={deleteOpen.show}
                        danger
                        primaryButtonText={"Delete"}
                        secondaryButtonText={"Cancel"}
                        onRequestClose={() => setDeleteOpen({...deleteOpen, show: false})}
                        onRequestSubmit={async () => await deleteFact()
                            .then(() => setDeleteOpen({...deleteOpen, show: false}))
                            .then(async () => auth.updateFacts())
                            .then(() => auth.toast.success("Successfully deleted selected facts"))
                            .then(() => setLoading(false))}
                        modalHeading={"Delete Fact"}
                    >
                        Are you sure that you want to delete the selected facts?
                    </Modal>

                    <Modal
                        open={createMappingOpen}
                        primaryButtonText={"Create"}
                        secondaryButtonText={"Cancel"}
                        onRequestClose={() => setCreateMappingOpen(false)}
                        onRequestSubmit={async () => await createMapping().then(() => setCreateMappingOpen(false))}
                        modalHeading={"Create Mapping"}
                    >
                        <TextInput
                            id={"new-mapping-question"}
                            labelText={"Question"}
                            placeholder={"Example: What is the largest species of penguin?"}
                            value={mappingQuestion}
                            onChange={(e) => setMappingQuestion(e.target.value)}
                            style={{
                                marginBottom: "1rem"
                            }}
                        />
                    </Modal>

                    <Modal
                        passiveModal
                        open={uploadModalOpen}
                        onRequestClose={() => {
                            setUploadModalOpen(false);
                            setUploadStatus("edit");
                        }}
                        modalHeading={"Import Facts"}
                    >
                        <FileUploader
                            labelDescription={"Only .csv files are supported. File must contain a column named fact."}
                            multiple={false}
                            filenameStatus={uploadStatus}
                            accept={[".csv"]}
                            buttonLabel={"Select File"}
                            onChange={async (e) => {
                                setUploadStatus("uploading")
                                await upload(e.target.files![0], auth.tenants?.active?.id).then(() => {
                                    setUploadStatus("complete");
                                })
                                    .then(async () => await auth.updateFacts())
                                    .then(() => setUploadModalOpen(false))
                            }}
                        />
                    </Modal>


                </>)

            ) : ""}

        </>
    )
}

export default Facts;