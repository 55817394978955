import React, {useState} from 'react';
// @ts-ignore
import { FlexGrid, Header, HeaderName, Modal, TextInput, Tile, Heading, SkeletonPlaceholder} from '@carbon/react';
import authenticationProvider from "../api/authenticationProvider";
import {AxisOptions, Chart} from "react-charts";
import useDemoConfig from '../useDemoConfig';

function Home(props: any) {
    const auth: authenticationProvider = props?.auth

    return (
        <div>
            {
                auth.tenants?.active?.statistics?.totalMessages ? (
                    <div>
                        <Tile style={{marginBottom: 16}}>
                            Total Tokens Billed
                            <Heading>
                                { auth.tenants?.active?.statistics?.totalTokensBilled }
                            </Heading>
                        </Tile>
                        <Tile style={{marginBottom: 16}}>
                            Total Tokens Billed USD$
                            <Heading>
                                { auth.tenants?.active?.statistics?.totalTokensBilledUSD.toFixed(2) }$
                            </Heading>
                        </Tile>
                        <Tile style={{marginBottom: 16}}>
                            Total Messages Received
                            <Heading>
                                { auth.tenants?.active?.statistics?.totalMessages }
                            </Heading>
                        </Tile>
                        <Tile style={{marginBottom: 16}}>
                            Average Tokens per Message
                            <Heading>
                                { auth.tenants?.active?.statistics?.tokensPerMessage.toFixed(0) }
                            </Heading>
                        </Tile>
                    </div>
                ) : (
                    <div>
                        <SkeletonPlaceholder  style={{width: "100%"}}/>
                    </div>
                )
            }
        </div>
    );
}

export default Home;